<template>
<div id="access">
  <div class="schedule">
     <div class="name">
      <div class="name-tenyukai">医療法人社団 天裕会</div>
      <div class="name-nozomi">のぞみクリニック築地</div>
    </div>
    <div class="address">
      <div>〒104-0045</div>
      <div>東京都中央区築地7-11-5 中銀ベル築地マンシオン2階</div>
      <div>東京メトロ日比谷線 築地駅1番、2番出口より徒歩4分</div>
    </div>
    <div class="tel">
      <div><img src="../../assets/icon/icon_tel_hover_28x28@2x.png" alt="" width="20" height="20"></div>
      <div class="tel-number">03-6260-6008</div>
    </div>
    <div class="table">
      <table>
        <tr>
          <td>診療時間</td>
          <td>月</td>
          <td>火</td>
          <td>水</td>
          <td>木</td>
          <td>金</td>
          <td>土</td>
          <td>日</td>
        </tr>
        <tr>
          <td>10:00 ～ 13:30</td>
          <td>○</td>
          <td>○</td>
          <td>休</td>
          <td>○</td>
          <td>○</td>
          <td>○</td>
          <td>休</td>
        </tr>
        <tr>
          <td>14:30 ～ 19:00</td>
          <td>○</td>
          <td>○</td>
          <td>休</td>
          <td>○</td>
          <td>○</td>
          <td>○</td>
          <td>休</td>
        </tr>
      </table>
    </div>
    <div class="notice">※ 受付は３０分前までとなります。</div>
    <div class="notice">※ 休診日：水曜・日曜・祝日・年末年始</div>
  </div>

  <div class="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1620.7302022458769!2d139.77382708041415!3d35.66566389811144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601889623b873a13%3A0x1c21f5e80c1e7f9d!2z44CSMTA0LTAwNDUg5p2x5Lqs6YO95Lit5aSu5Yy656-J5Zyw77yX5LiB55uu77yR77yR4oiS77yVIOS4remKgOODmeODq-evieWcsOODnuODs-OCt-OCquODsyAy6ZqO!5e0!3m2!1sja!2sjp!4v1633341603803!5m2!1sja!2sjp" width="100%" height="400" style="border:2px solid #62b6e2;" allowfullscreen="" loading="lazy"></iframe>
  </div>

  <div class="info">
    <div class="btn-online" @click="goTo('/department/telemedicine')">
      <div class="btn-icon"></div>
      <div class="btn-text">
        <div>WEB予約</div>
        <div class="sub-text">オンライン診療はこちらから</div>
      </div>
    </div>
    <div class="btn-online" style="margin-top: 14px;" @click="goTo('/sending')">
      <div class="btn-icon-mail"></div>
      <div class="btn-text">お問い合わせ</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "MAccess",
  methods: {
    goTo(page) {
      this.$router.push(`${page}`)
    }
  }
}
</script>

<style scoped>
#access {
  padding: 30px 20px;
}
/* 病院情報 */
.schedule .name-tenyukai {
  color: #9E9E9E;
  font-size: 10px;
  font-weight: bold;
}
.schedule .name-nozomi {
  color: #62B6E2;
  font-size: 16px;
  font-weight: bold;
}
.schedule .address {
  font-size: 12px;
  margin-top: 10px;
}
.schedule .tel {
  align-items: flex-start;
  display: flex;
  margin-top: 14px;
}
.schedule .tel .tel-number {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
}
/* 診療時間 */
.schedule .table table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  margin-top: 10px;
  width: 100%;
}
.schedule .table table tr:first-child {
  background-color: #f0f0f0;
}
.schedule .table table td {
  border: 1px solid #cccccc;
  text-align: center;
  padding: 8px 12px;
}
.schedule .notice {
  font-size: 12px;
  margin-top: 10px;
}
/* MAP */
.map {
  margin-top: 20px;
}
/* ボタン */
.info {
  margin-top: 20px;
}
.info .btn-online {
  align-items: center;
  background-color: #62B6E2;
  border: 2px solid #62B6E2;
  border-radius: 5px;
  box-shadow: 0 2px 4px #CCCCCC;
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  transition: 50ms;
  height: 40px;
}
.info .btn-online .btn-icon{
  height: 36px;
  width: 36px;
  background-image: url("../../assets/icon/icon_calendar_36x36@2x.png");
  background-size: 100%;
  transition: 50ms;
}

.info .btn-online .btn-icon-mail{
  height: 36px;
  width: 36px;
  background-image: url("../../assets/icon/icon_mail_36x36@2x.png");
  background-size: 100%;
  transition: 50ms;
}

.info .btn-online:active{
  background-color: #ffffff;
}

.info .btn-online:active .btn-icon{
  background-image: url("../../assets/icon/icon_calendar_hover_36x36@2x.png");
}

.info .btn-online:active .btn-icon-mail{
  background-image: url("../../assets/icon/icon_mail_hover_36x36@2x.png");
}

.info .btn-online:active .btn-text{
  color: #62B6E2;
  font-size: 14px;
}

.info .btn-online:active .btn-text .sub-text{
  color: #62B6E2;
  font-size: 10px;
}

.info .btn-mail {
  align-items: center;
  background-color: #FFFFFF;
  border: 2px solid #62B6E2;
  border-radius: 5px;
  box-shadow: 0 2px 4px #CCCCCC;
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  margin-top: 14px;
  height: 40px;
}
.info .btn-text {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  transition: 50ms;
}
.info .btn-text .sub-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  transition: 50ms;
}
</style>
